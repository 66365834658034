var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"feed-body"},[_c('div',{staticStyle:{"width":"100%","max-height":"calc(100vh - 150px)","overflow":"hidden","position":"relative"}},[_c('v-carousel',{attrs:{"cycle":"","interval":"5000","hide-delimiters":""}},_vm._l((_vm.images),function(item){return _c('v-carousel-item',{key:item._id},[_c('v-parallax',{attrs:{"src":item.image,"width":"100%"}})],1)}),1),_c('div',{staticClass:"title-section"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',[_vm._v("Get the opportunity to invest your time in")]),_c('div',{staticStyle:{"font-family":"'Poppins-Bold'"}},[_vm._v("New experiences")]),_c('v-text-field',{staticClass:"title-section-text-field",attrs:{"color":"white","label":"Search event","dark":""},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSearchKey($event)}},model:{value:(_vm.searchKey),callback:function ($$v) {_vm.searchKey=$$v},expression:"searchKey"}})],1)])],1),_c('div',[_c('v-row',{staticClass:"pt-0 ma-0"},_vm._l((_vm.events),function(et){return _c('v-col',{key:et._id,staticClass:"pa-0",attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[_c('v-img',{class:{ zoom: !hover, triggerzoom: hover },attrs:{"src":et.cover,"width":"100%","height":"100%","max-height":"280px"}},[_c('div',{class:{
                  'gradient-background': !hover,
                  'more-gradient-background': hover,
                }}),_c('div',{class:{ notselected: !hover, selectedEventText: hover },on:{"click":function($event){return _vm.goToDetails(et._id)}}},[_c('div',{staticStyle:{"color":"white","padding":"20px"}},[_c('div',{staticStyle:{"word-break":"break-word","font-weight":"bold"}},[_vm._v(" "+_vm._s(et.title)+" ")]),(hover)?_c('div',{staticClass:"event-text",staticStyle:{"color":"white","margin-right":"20px","margin-top":"10px"}},[_vm._v(" "+_vm._s(et.text)+" ")]):_vm._e()])])])],1)]}}],null,true)})],1)}),1)],1),_c('div',{staticClass:"calendar-section"},[_c('v-img',{attrs:{"src":require("@/assets/events/bg_event_calendar_section.png"),"height":"100%"}},[_c('div',{staticClass:"d-flex flex-column align-center"},[_c('div',{staticClass:"calendar-section-title"},[_vm._v(" Do you have days off? ")]),_c('div',{staticClass:"calendar-section-description"},[_vm._v(" Search and find the most outstanding events in Framingham and in your city. The most prominent will appear in your news section. Have fun and enjoy every momment. ")]),_c('div',{staticClass:"mt-6"},[_c('v-row',[_c('v-col',{staticClass:"calendar-image-section",attrs:{"cols":"12","sm":"6"}},[_c('v-img',{staticClass:"calendar-image-section-image",attrs:{"src":require("@/assets/events/img_calendar.png"),"contain":""}})],1),_c('v-col',{staticClass:"calendar-date-section",attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"calendar-date-section-description"},[_vm._v(" Find an event that meets your needs and is close to you ")]),_c('div',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.searchDate,"transition":"scale-transition","min-width":"auto"},on:{"update:returnValue":function($event){_vm.searchDate=$event},"update:return-value":function($event){_vm.searchDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mt-15",attrs:{"label":"Date","clearable":""},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSaveDate($event)}},model:{value:(_vm.searchDateStr),callback:function ($$v) {_vm.searchDateStr=$$v},expression:"searchDateStr"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.searchDate),callback:function ($$v) {_vm.searchDate=$$v},expression:"searchDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateMenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.onSaveDate}},[_vm._v(" OK ")])],1)],1)],1),_c('v-btn',{staticClass:"mt-10",attrs:{"dark":"","color":"#7024c4"},on:{"click":_vm.onSearchDate}},[_vm._v("Search")])],1)],1)],1)])])],1),_c('featured-events'),_c('event-list-section'),_c('bottom-bar'),_c('confirm-delete-dialog',{attrs:{"deleteDialog":_vm.deleteDialog,"loading":_vm.loading,"onClose":_vm.onCloseDialog,"onConfirm":_vm.onConfirmDelete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }