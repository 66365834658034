<template>
  <div class="body-content" v-if="events && events.length">
    <div
      style="font-size: 40px; font-family: 'Poppins-SemiBold'; color: #7024c4;"
    >
      Featured
    </div>
    <div style="width: 100%; margin-top: 40px;">
      <div v-for="(event, index) in events" :key="event._id" class="mt-5 mb-15">
        <v-row style="width: 100%">
          <v-col
            cols="12"
            md="7"
            v-if="!(index % 2) || $vuetify.breakpoint.smAndDown"
          >
            <v-img :src="event.cover" />
          </v-col>
          <v-col cols="12" md="5">
            <div class="d-flex">
              <div
                class="url-button event-title"
                @click="goToEventDetail(event._id)"
              >
                {{ event.title }}
              </div>
              <div class="mb-5">
                <v-img
                  src="@/assets/events/rect_date_outline.png"
                  width="80"
                  height="80"
                  contain
                >
                  <div
                    class="d-flex flex-column align-center; justify-center;"
                    style="width: 100%;"
                  >
                    <div
                      style="color: #7024c4; font-family: 'Poppins-SemiBold'; font-size: 30px; text-align: center; margin-top: 4px;"
                    >
                      {{ getDate(event.createdAt) }}
                    </div>
                    <div
                      style="color: #7024c4; font-family: 'Poppins-SemiBold'; font-size: 14px; text-align: center; margin-top: 3px;"
                    >
                      {{ getMonth(event.createdAt) }}
                    </div>
                  </div>
                </v-img>
              </div>
            </div>
            <div class="text">
              {{ event.text }}
            </div>
          </v-col>
          <v-col cols="7" v-if="index % 2 && $vuetify.breakpoint.mdAndUp">
            <v-img :src="event.cover" />
          </v-col>
        </v-row>
        <v-divider class="hidden-md-and-up mt-10" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import dateFormat from "dateformat";
export default {
  data() {
    return {
      events: [],
    };
  },
  methods: {
    ...mapActions("event", {
      fetchAllEvents: "fetchAllEvents",
      fetchUserEvents: "fetchUserEvents",
    }),
    loadEvents() {
      console.log("load events");
      if (
        this.profileType == "User" &&
        this.profile.interests &&
        this.profile.interests.length
      ) {
        console.log("fetch user events");
        this.loading = true;
        this.fetchUserEvents()
          .then((res) => {
            this.loading = false;
            this.events = res;
            console.log(res);
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      } else {
        console.log(this.profile);
        this.loading = true;
        this.fetchAllEvents()
          .then((res) => {
            this.loading = false;
            this.events = res;
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      }
    },
    convertTZ(date) {
      if (!date) {
        return new Date(
          new Date().toLocaleString("en-US", {
            timeZone: "America/New_York",
          })
        );
      }
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: "America/New_York",
          }
        )
      );
    },
    getDate(dateStr) {
      return dateFormat(this.convertTZ(dateStr), "d");
    },
    getMonth(dateStr) {
      return dateFormat(this.convertTZ(dateStr), "mmmm");
    },
    goToEventDetail(_id) {
      this.$router.push({ name: "event-details", query: { _id } });
    },
  },
  computed: {
    ...mapGetters({
      profile: "auth/getProfile",
      profileType: "auth/getType",
    }),
  },
  watch: {
    profile(newValue) {
      console.log("profile update", newValue);
      if (
        newValue &&
        this.profileType == "User" &&
        newValue.interests &&
        newValue.interests.length
      ) {
        console.log("load event again");
        this.loadEvents();
      } else {
        console.log("invalid user");
      }
    },
  },
  mounted() {
    this.loadEvents();
  },
};
</script>
<style scoped>
.body-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.event-title {
  font-size: 20px;
  color: #1c1c1a;
  font-family: "Poppins-SemiBold";
  flex-grow: 1;
  margin-top: 50px;
  padding-right: 20px;
}
.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
  color: #423f63;
}

@media only screen and (max-width: 600px) {
  .body-content {
    max-width: 100%;
    margin-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .body-content {
    max-width: 100%;
    margin-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .body-content {
    max-width: 100%;
    margin-top: 50px;
  }
}
@media only screen and (min-width: 992px) {
  .body-content {
    max-width: 100%;
    margin-top: 50px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .body-content {
    max-width: 1000px;
    margin-top: 50px;
  }
}
</style>
