<template>
  <div class="feed-body">
    <div
      style="width: 100%; max-height: calc(100vh - 150px); overflow: hidden; position: relative;"
    >
      <v-carousel cycle interval="5000" hide-delimiters>
        <v-carousel-item v-for="item in images" :key="item._id">
          <v-parallax :src="item.image" width="100%" />
        </v-carousel-item>
      </v-carousel>
      <div class="title-section">
        <div class="d-flex flex-column">
          <div>Get the opportunity to invest your time in</div>
          <div style="font-family: 'Poppins-Bold';">New experiences</div>
          <v-text-field
            v-model="searchKey"
            class="title-section-text-field"
            color="white"
            label="Search event"
            @keypress.enter="onSearchKey"
            dark
          />
        </div>
      </div>
    </div>
    <div>
      <v-row class="pt-0 ma-0">
        <v-col v-for="et in events" :key="et._id" cols="12" md="4" class="pa-0">
          <v-hover v-slot="{ hover }">
            <transition name="slide-fade" mode="out-in">
              <v-img
                :src="et.cover"
                width="100%"
                height="100%"
                max-height="280px"
                :class="{ zoom: !hover, triggerzoom: hover }"
              >
                <div
                  :class="{
                    'gradient-background': !hover,
                    'more-gradient-background': hover,
                  }"
                />
                <div
                  :class="{ notselected: !hover, selectedEventText: hover }"
                  @click="goToDetails(et._id)"
                >
                  <div style="color: white; padding: 20px;">
                    <div style="word-break: break-word; font-weight: bold;">
                      {{ et.title }}
                    </div>
                    <div
                      v-if="hover"
                      class="event-text"
                      style="color: white; margin-right: 20px; margin-top: 10px;"
                    >
                      {{ et.text }}
                    </div>
                  </div>
                </div>
              </v-img>
            </transition>
          </v-hover>
        </v-col>
      </v-row>
    </div>
    <div class="calendar-section">
      <v-img src="@/assets/events/bg_event_calendar_section.png" height="100%">
        <div class="d-flex flex-column align-center">
          <div class="calendar-section-title">
            Do you have days off?
          </div>
          <div class="calendar-section-description">
            Search and find the most outstanding events in Framingham and in
            your city. The most prominent will appear in your news section. Have
            fun and enjoy every momment.
          </div>
          <div class="mt-6">
            <v-row>
              <v-col cols="12" sm="6" class="calendar-image-section">
                <v-img
                  src="@/assets/events/img_calendar.png"
                  contain
                  class="calendar-image-section-image"
                />
              </v-col>
              <v-col class="calendar-date-section" cols="12" sm="6">
                <div class="calendar-date-section-description">
                  Find an event that meets your needs and is close to you
                </div>
                <div>
                  <v-menu
                    ref="menu"
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :return-value.sync="searchDate"
                    transition="scale-transition"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="searchDateStr"
                        v-bind="attrs"
                        v-on="on"
                        label="Date"
                        clearable
                        @keypress.enter="onSaveDate"
                        class="mt-15"
                      />
                    </template>
                    <v-date-picker v-model="searchDate" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dateMenu = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="onSaveDate">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>
                <v-btn @click="onSearchDate" class="mt-10" dark color="#7024c4"
                  >Search</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </div>
      </v-img>
    </div>
    <featured-events />
    <event-list-section />
    <bottom-bar />
    <confirm-delete-dialog
      :deleteDialog="deleteDialog"
      :loading="loading"
      :onClose="onCloseDialog"
      :onConfirm="onConfirmDelete"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
import ConfirmDeleteDialog from "@/components/ConfirmDeleteDialog.vue";
import FeaturedEvents from "./FeaturedEvents.vue";
import EventListSection from "./EventListSection.vue";
import BottomBar from "../../components/core/BottomBar.vue";
import dateFormat from "dateformat";
export default {
  components: {
    ConfirmDeleteDialog,
    FeaturedEvents,
    EventListSection,
    BottomBar,
  },
  name: "EventDetails",
  data() {
    return {
      deleteDialog: false,
      loading: false,
      searchKey: "",
      dateMenu: false,
      searchDate: null,
      searchDateStr: "",
      events: [],
    };
  },
  methods: {
    ...mapActions({
      getEventDetails: "event/getEventDetails",
      deleteEvent: "event/deleteEvent",
      fetchAllEvents: "event/fetchAllEvents",
      fetchUserEvents: "event/fetchUserEvents",
      fetchAllHomeImages: "variable/fetchAllHomeImages",
    }),
    onEditEvent() {
      this.$router.push({ name: "edit-event", query: { _id: this.event._id } });
    },
    goToAgencyProfile() {
      this.$router.push({
        name: "agency-profile-details",
        params: { id: this.event.poster },
      });
    },
    onDeleteEvent() {
      this.deleteDialog = true;
    },
    onCloseDialog() {
      this.deleteDialog = false;
    },
    onConfirmDelete() {
      this.loading = true;
      this.deleteEvent({ _id: this.event._id })
        .then(() => {
          this.loading = false;
          this.$router.back();
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            this.snackbar = true;
            this.errorMessage = error.response.data.message;
          } else {
            this.snackbar = true;
            this.errorMessage = error;
          }
        });
    },
    loadEvents() {
      if (
        this.profileType == "User" &&
        this.profile.interests &&
        this.profile.interests.length
      ) {
        this.loading = true;
        this.fetchUserEvents()
          .then((res) => {
            this.loading = false;
            this.events = res;
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      } else {
        this.loading = true;
        this.fetchAllEvents()
          .then((res) => {
            this.loading = false;
            this.events = res;
          })
          .catch((error) => {
            console.log(error.response.data.message);
          });
      }
    },
    goToDetails(_id) {
      if (this.$route.name != "event-details" || this.$route.query._id != _id) {
        this.$router.push({ name: "event-details", query: { _id } });
      }
    },
    onSaveDate() {
      var date = new Date(this.searchDate + " EST");
      this.searchDateStr = dateFormat(date, "mm/dd/yyyy");
      this.dateMenu = false;
    },
    onSearchDate() {
      if (this.searchDateStr) {
        this.$router.push({
          name: "event-search",
          query: { date: this.searchDateStr },
        });
      }
    },
    onSearchKey() {
      if (this.searchKey) {
        this.$router.push({
          name: "event-search",
          query: { key: this.searchKey },
        });
      }
    },
    checkInterests() {
      console.log("check interests", this.profile, this.profile.interests);
      if (this.profile && this.profile._id) {
        if (
          this.profileType == "User" &&
          (!this.profile.interests || this.profile.interests.length == 0)
        ) {
          this.$router.push({ name: "select-interests" });
        } else {
          console.log(this.profileType, this.profile.interests);
        }
      } else {
        console.log("profile is null");
      }
    },
  },
  computed: {
    ...mapGetters({
      profile: "auth/getProfile",
      profileType: "auth/getType",
      images: "variable/getAllHomeImages",
    }),
    keyword: {
      get() {
        return this.$store.state.auth.keyword;
      },
      set(value) {
        this.$store.commit("auth/setKeyword", value);
      },
    },
  },
  watch: {
    profile(newValue) {
      if (newValue && newValue.id) {
        this.checkInterests();
      }
    },
  },
  mounted() {
    this.keyword = "";
    this.loadEvents();
    this.checkInterests();
    this.fetchAllHomeImages();
  },
};
</script>
<style scoped>
.feed-body {
  width: 100%;
  min-width: 200px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.v-parallax__image {
  transform: none !important;
  width: 100% !important;
}
.gradient-background {
  background: #7024c4;
  background: linear-gradient(0deg, #7024c4 0%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  height: 80%;
  position: absolute;
  bottom: 0px;
}
.more-gradient-background {
  background: #7024c4;
  background: linear-gradient(0deg, #7024c4 0%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0px;
}
.notselected {
  width: 100%;
  position: absolute;
  bottom: 10px;
  left: 10px;
}
.selectedEventText {
  width: 100%;
  position: absolute;
  top: 10px;
  left: 10px;
}
.title-section {
  color: white;
  position: absolute;
  bottom: 50px;
  font-size: 58px;
  font-family: "Poppins-Medium";
  left: 50px;
  max-width: calc(100vw - 100px);
}
.title-section-text-field {
  width: 500px;
}

.calendar-section {
  height: 800px;
}
.calendar-section-title {
  color: #7024c4;
  font-size: 40px;
  font-family: "Poppins-SemiBold";
  margin-top: 100px;
}
.calendar-section-description {
  color: #423f63;
  font-size: 20px;
  font-family: "Poppins-Regular";
  padding-top: 50px;
  margin-bottom: 10px;
  margin-left: 50px;
  margin-right: 50px;
  max-width: 1100px;
  text-align: center;
}
.calendar-image-section {
  display: flex;
  justify-content: flex-end;
}
.calendar-image-section-image {
  width: 600px;
  height: 500px;
}
.calendar-date-section {
  color: #423f63;
  font-family: "Poppins-SemiBold";
  font-size: 20px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.calendar-date-section-description {
  max-width: 400px;
  margin-top: 50px;
}
.zoom >>> .v-image__image {
  transition: transform 0.5s; /* Animation */
}

.triggerzoom >>> .v-image__image {
  transition-duration: 0.5s;
  transform: scale(
    1.2
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.event-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
  color: #423f63;
}

div.v-date-picker-table >>> table > tbody > tr > td {
  padding: 0 !important;
}

@media only screen and (max-width: 600px) {
  .title-section {
    font-size: 30px;
  }
  .title-section-text-field {
    width: 300px;
  }
  .calendar-section {
    height: 900px;
  }
  .calendar-section-title {
    color: #7024c4;
    font-size: 30px;
    font-family: "Poppins-SemiBold";
    margin-top: 50px;
  }
  .calendar-section-description {
    font-size: 14px;
    padding-top: 30px;
  }
  .calendar-image-section {
    display: flex;
    justify-content: center;
  }
  .calendar-image-section-image {
    width: 400px;
    height: 300px;
  }
  .calendar-date-section {
    font-size: 14px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 60px;
    padding-right: 60px;
  }
  .calendar-date-section-description {
    max-width: 300px;
    margin-top: 10px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .title-section {
    font-size: 32px;
  }
  .title-section-text-field {
    width: 400px;
  }
}

@media only screen and (min-width: 768px) {
  .title-section {
    font-size: 58px;
  }
}
</style>
